






























































































































































































































































































































































































































































































.show_statement_details {
  .el-dialog__close {
    display: block !important;
    cursor: pointer;
  }
}
.team_category_wrapper {
  .actions_wrapper {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 5px 0;
    .actions-icons {
      font-size: 14px;
      width: 30px;
      height: 30px;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #777;
      transition: 0.3s;

      &.add {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }

      &.edit {
        &:hover {
          background-color: #ffa534;
          color: #fff;
        }
      }

      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
}

.category_for_large {
  display: block !important;
  @media (min-width: 320px) and (max-width: 776px) {
    display: none !important;
  }
}
.category_for_mobile {
  display: none !important;
  @media (min-width: 320px) and (max-width: 776px) {
    display: block !important;
  }
}
// ::v-deep .el-table_1_column_2 {
//   @media (min-width: 320px) and (max-width: 776px) {
//     display: none !important;
//   }
// }
